<template>
  <b-card
    body-class="p-0"
    class="container-card-category m-0 mr-1"
    :style="getWidth()"
    @click="clickCard(casting_call)"
  > 
    <div class="d-flex align-items-center p-1">
      <b-avatar icon="shop" :src="img_logo" size="50" class="mr-1"></b-avatar>
      <div>
        <strong class="text-overflow-casting">{{casting_call.brand.name}}</strong>
        <p class="m-0 avenir-medium">{{$t('creator.type_of_service').find(response => response.value === casting_call.content_type).text}}</p>
      </div>
    </div>
    <div class="position-relative">
      <b-img
        class="img-casting-call"
        :src="main_image_content"
        alt="Responsive image"
        @error="errorLoad"
      >
      </b-img>
      <div :style="getBackgroundColor()" class="background-hover-image"></div>
      <div class="text-hover-image-title">
        {{casting_call.title}}
      </div>
    </div>
    <div class="template-footer">  
      <div class="d-flex justify-content-between">
        <b-badge class="light-secondary-v2 d-flex align-items-center" variant="light-secondary-v2">
          <feather-icon icon="CalendarIcon" size="24" class="mr-05"></feather-icon>
          <span>{{casting_call.date_delivery ? getDatev2(new Date(casting_call.date_delivery)) : $t('creator.ongoing')}}</span>
        </b-badge>
        
          <b-avatar
          class="background-color-avatar" 
          v-for="compensation in casting_call.compensations" :key="compensation.uuid" 
          variant="light-secondary" 
          :icon="$t('creator.compensation_type').find(response => response.value === compensation.type_compensation).icon" 
          size="25"
          :id="'tooltip-info-' + compensation.uuid"
          >
          <b-tooltip v-if="casting_call.type_compensation === 'fixed'" :target="'tooltip-info-' + compensation.uuid" variant="dark"> {{getCompensation(compensation.type_compensation).text2}} - $ {{ numberFormat(compensation.amount, true) }}</b-tooltip>
          <b-icon v-if="getCompensation(compensation.type_compensation).icon" :icon="getCompensation(compensation.type_compensation).icon" class="icon-green"></b-icon>
          <b-img v-else class="icon-svg" :src="IconTypeCompetationGreen(compensation.type_compensation)"></b-img>
          </b-avatar>
          
      </div>
    </div>
  </b-card>
</template>
      
<script>
import { 
  BTooltip,
  BBadge,
  BCard,
  BImg,
  BAvatar,
} from "bootstrap-vue";
import { getAssetUrl } from '@/libs/utils/urls';
import { getDatev2 } from '@/libs/utils/times'
import { IconTypeCompetationGreen } from '@/libs/utils/icons';
import { numberFormat } from '@/libs/utils/formats'

export default {
  name: 'cardCastingCall',
  components: {
    BTooltip,
    BBadge,
    BCard,
    BImg,
    BAvatar,
  },
  props: {
    width: {
      type: String,
      default: '250px'
    },
    color: {
      type: String,
      default: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(160,0,255,1) 100%);'
    },
    casting_call: {
      type: Object,
    },
    redirect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      numberFormat,
      IconTypeCompetationGreen,
      getDatev2,
      img_logo: this.casting_call.brand.logo_url ? this.casting_call.brand.logo_url : getAssetUrl(this.casting_call.brand.logo), 
      main_image_content: this.casting_call.cover_url ? this.casting_call.cover_url : getAssetUrl(this.casting_call.cover_image),
      timeouts: []
    }
  },
  methods: {
    getCompensation(compensation_value) {
      return this.$t('creator.compensation_type').find((c) => c.value === compensation_value)
    },
    errorLoad() {
      this.main_image_content = require("@/assets/images/dashboard/imgDefault.png");
    },
    clickCard(casting_call) {
      const url = `${window.location.origin}/view-casting-call/${casting_call.slug}`
      !this.redirect ? this.$router.push({name: 'view_casting_call', params: {slug: casting_call.slug}}) : window.open(url, '_blank');
    },
    getWidth() {
      return `width: ${this.width} !important;`
    },
    getBackgroundColor() {
      return `background: linear-gradient(90deg, rgba(2,0,36,1) 0%, ${this.casting_call.main_color} 100%);}`
    },
  }
};
</script>

<style>
</style> 
<style scoped>
.icon-svg {
  width: 18px;
}
.icon-green {
  color: #255E55
}
.text-overflow-casting {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.mr-05 {
  margin-right: 0.5em;
}
.background-hover-image {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0.5;
}
.container-card-category {
  /* height: 458px; */
  cursor: pointer;
  border-radius: 1em;
  box-shadow: none !important;
  border: 1px solid #eee5e5 !important;
}
.container-card-category:hover {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
  transition: all 300ms;
}
.img-casting-call {
  width: 100%;
  height: 200px;
  object-fit: cover;
}
.text-hover-image-title {
  color: white;
  position: absolute;
  bottom: 0.5em;
  left: 0.5em;
  font-size: 21px;
}
.light-secondary-v2 {
  background-color: #e8e8e8;
  color: #718096;
  font-weight: 400;
}
.background-color-avatar {
  background-color: #e8e8e8;
}
.template-footer {
  height: auto !important;
  padding: 7px;
  background-color: white;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
}
</style>